import {
  container,
  main,
  mainRaised,
} from "assets/jss/material-kit-pro-react.js";
import { blackColor, hexToRgb, title } from "assets/jss/material-kit-pro-react";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle";

const profilePageStyle = (theme) => ({
  container,
  ...modalStyle(theme),
  main: {
    ...main,
  },
  mainRaised: {
    ...mainRaised,
  },
  wrapperClassName: {
    marginTop: "2rem",
  },
  editorClassName: {
    minHeight: "25vh",
    border: `1px solid rgba(0,0,0,0.1)`,
    padding: `0.5rem`,
  },
  imgRoundedCircle: {
    borderRadius: "50% !important",
  },
  imgRaised: {
    boxShadow:
      "0 5px 15px -8px rgba(" +
      hexToRgb(blackColor) +
      ", 0.24), 0 8px 10px -5px rgba(" +
      hexToRgb(blackColor) +
      ", 0.2)",
  },
  imgFluid: {
    maxWidth: "100%",
    height: "46px",
    width: "46px",
  },
  imgFluidView: {
    maxWidth: "100%",
    height: "60px",
    width: "60px",
  },
  imgPosition: {
    // position: "absolute",
    // top: "-23px",
  },
  infoElement: {
    height: "46px",
  },
  badge: {
    marginLeft: "1rem",
  },
  title: {
    ...title,
    margin: "0 0 0 1rem !important",
    minHeight: "unset",
  },
});

export default profilePageStyle;

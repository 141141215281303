import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import homeBanner from "../../assets/img/sanoboard/home-banner.jpg";
import PropTypes from "prop-types";
import {
  container,
  secondaryColor,
} from "assets/jss/material-kit-pro-react.js";
import classNames from "classnames";

const useStyles = makeStyles({
  container,
  bannerContainer: {
    paddingTop: "70px",
    position: "relative",
    maxHeight: "370px",
  },
  banner: {
    height: "300px",
    overflow: "hidden",
    background: "linear-gradient(rgb(36 80 183 / 50%), rgba(46,59,89,0.5))",
  },
  bannerImage: {
    width: "100%",
    zIndex: "-1",
    position: "relative",
  },
  bannerTitle: {
    display: "block",
    width: "100%",
    textAlign: "center",
    color: secondaryColor[0],
    fontWeight: "bold",
    textShadow: "1px 1px 2px rgba(255,255,255,0.5)",
  },
  bannerInfoContainer: {
    overflow: "hidden",
    position: "relative",
    height: "300px",
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "column",
    left: "50%",
    transform: "translate(-50%, -300px)",
  },
  bannerDescription: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
    textShadow: "1px 1px 2px rgba(255,255,255,0.5)",
    "& p": {
      display: "inline",
    },
    "& .wysiwyg-mention": {
      color: secondaryColor[0],
      transition: "all 0.1s ease-in-out",
      "&:hover": {
        transform: "scale(1.1)",
        color: secondaryColor[0],
      },
    },
  },
});

export default function StandardPage({
  children,
  title,
  headerUrl,
  description,
}) {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.bannerContainer}>
        <div className={classes.banner}>
          <img
            className={classes.bannerImage}
            src={headerUrl ? headerUrl : homeBanner}
            alt={"banner"}
          />
        </div>
        <div className={classes.bannerInfoContainer}>
          <h2 className={classNames(classes.bannerTitle, classes.container)}>
            {title}
          </h2>
          <div
            className={classNames(classes.bannerDescription, classes.container)}
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        </div>
      </div>
      <div className={classes.container}>{children}</div>
    </div>
  );
}

StandardPage.defaultProps = {
  title: "",
  description: "",
  headerUrl: null,
};

StandardPage.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  headerUrl: PropTypes.string,
};

import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { push, replace } from "connected-react-router";
import { toastr } from "react-redux-toastr";
import Paths from "../staticData/Paths";
import Endpoints from "../staticData/Endpoints";
import { displayValidationMessage } from "../utils/validationDisplayHelper";
import { createSelector } from "reselect/src";
import { clearBoards } from "./generalBoardsSlice";
import { clearPosts } from "./generalPostsSlice";
import { getMyAccountProfile } from "./myAccountSlice";

const existingTokens = JSON.parse(localStorage.getItem("tokens"));

const authenticationSlice = createSlice({
  name: "authentication",
  initialState: {
    authTokens: existingTokens,
    role: "",
    userData: {},
    needsUsernameUpdate: false,
  },
  reducers: {
    setAuthTokens(state, action) {
      state.authTokens = action.payload;
    },
    setRole(state, action) {
      state.role = action.payload;
    },
    setUserData(state, action) {
      state.userData = action.payload;
      state.role = action.payload.role;
      state.needsUsernameUpdate = action.payload.needsUsernameUpdate;
    },
    setProfilePicture(state, { payload: { profilePicture } }) {
      state.userData.profilePicture = profilePicture;
    },
  },
});

export const {
  setAuthTokens,
  setUserData,
  setRole,
  setProfilePicture,
} = authenticationSlice.actions;

export default authenticationSlice.reducer;

export const authenticationSelectors = {
  userData: createSelector(
    (state) => state.authentication.userData,
    (userData) => userData
  ),
  userRole: createSelector(
    (state) => state.authentication.role,
    (role) => role
  ),
  authTokens: createSelector(
    (state) => state.authentication.authTokens,
    (token) => token
  ),
};

export const setTokens = (data) => (dispatch) => {
  if (data) {
    localStorage.setItem("tokens", JSON.stringify(data));
  } else {
    localStorage.removeItem("tokens");
  }
  dispatch(setAuthTokens(data));
};

export const loginUser = (data) => (dispatch) => {
  return axios({
    method: "POST",
    url: Endpoints.login,
    data: JSON.stringify(data),
    headers: { "content-type": "application/json" },
  })
    .then(handleLogin(dispatch))
    .then((success) => {
      if (success) dispatch(push(Paths.library));
    })
    .catch((error) => displayValidationMessage(error, dispatch));
};

export const loginWithFacebook = (data) => (dispatch) => {
  const payload = { accessToken: data.accessToken };

  return axios({
    method: "POST",
    url: Endpoints.facebookAuth,
    data: JSON.stringify(payload),
    headers: { "content-type": "application/json" },
  })
    .then(handleLogin(dispatch))
    .then((success) => {
      if (success) dispatch(replace(Paths.root));
    });
};

export const loginWithLinkedIn = ({ code }) => (dispatch) => {
  return axios({
    method: "GET",
    url: Endpoints.linkedInAuth,
    params: { code },
    headers: { "content-type": "application/json" },
  })
    .then(handleLogin(dispatch))
    .then((success) => {
      if (success) dispatch(replace(Paths.root));
    });
};

const handleLogin = (dispatch) => (response) => {
  const { succeeded, token, message, role } = response.data;

  if (!succeeded) {
    toastr.error(message);

    return false;
  }

  if (succeeded) {
    token && dispatch(setTokens(token));
    role && dispatch(setRole(role));

    toastr.success(message);

    return true;
  }
};

export const registerUser = (data) => (dispatch) => {
  const { termsAndConditions, ...payload } = data;

  if (!termsAndConditions) {
    toastr.error(
      "",
      "Please accept terms and conditions in order to continue."
    );
    return;
  }

  return axios({
    method: "POST",
    url: Endpoints.register,
    data: JSON.stringify(payload),
    headers: { "content-type": "application/json" },
  })
    .then((response) => {
      const { succeeded, token, message, registrationErrors } = response.data;

      if (!succeeded && message) {
        toastr.error("", message);
      }

      if (!succeeded && registrationErrors?.length) {
        registrationErrors.forEach((error) => {
          toastr.error("", error.description);
        });
      }

      if (succeeded) {
        dispatch(setTokens(token));
        toastr.success("", message);

        return true;
      }
    })
    .catch((error) => displayValidationMessage(error, dispatch));
};

export const recoverPassword = (data) => (dispatch) => {
  axios({
    method: "POST",
    url: Endpoints.requestResetPassword,
    data: JSON.stringify(data),
    headers: { "content-type": "application/json" },
  })
    .then((response) => {
      if (response.data === true) {
        dispatch(push(Paths.root));
        toastr.success(
          "",
          "An email containing a reset token was successfully sent. Please check your email.",
          { timeOut: 0 }
        );
      }
    })
    .catch((error) => displayValidationMessage(error, dispatch));
};

export const resetPassword = (data) => (dispatch) => {
  axios({
    method: "POST",
    url: Endpoints.resetPassword,
    data: JSON.stringify(data),
    headers: { "content-type": "application/json" },
  })
    .then((response) => {
      if (response.data === true) {
        dispatch(push(Paths.login));
        toastr.success("", "Password has been successfully changed.");
      }
    })
    .catch((error) => displayValidationMessage(error, dispatch));
};

export const uploadProfilePicture = ({ file }) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;

  const formData = new FormData();
  formData.append("picture", file);

  return axios({
    method: "POST",
    url: Endpoints.updateProfilePicture,
    data: formData,
    headers: {
      "content-type": "multipart/form-data",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then((result) => {
      const { status, data } = result;

      if (status === 200) {
        const profilePicture = `${
          data.base64ProfileImage
        }?${new Date().getTime()}`;

        toastr.success("", "Profile picture was changed successfully.");

        dispatch(setProfilePicture({ profilePicture }));
        dispatch(getMyAccountProfile());
        return true;
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getMyAccount = () => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;

  return axios({
    method: "GET",
    url: Endpoints.getMyAccount,
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then((result) => {
      const { status, data } = result;

      if (status === 200) {
        dispatch(setUserData(data));
      }
    })
    .catch((error) => {
      if (error.response.status === 401) {
        dispatch(setTokens());
        dispatch(setUserData({}));
        dispatch(clearBoards());
        dispatch(clearPosts());
      }
    });
};

export const updateProfile = ({ formValues }) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;

  const data = [
    { op: "replace", path: `/firstName`, value: formValues.firstName },
    { op: "replace", path: `/lastName`, value: formValues.lastName },
    { op: "replace", path: `/userName`, value: formValues.userName },
    { op: "replace", path: `/email`, value: formValues.email },
    { op: "replace", path: `/bio`, value: formValues.userBio },
  ];

  return axios({
    method: "PATCH",
    url: Endpoints.updateProfile,
    data: data,
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then((result) => {
      const { status } = result;

      if (status === 200) {
        toastr.success("", "Profile was successfully updated.");
      }
    })
    .catch((error) => displayValidationMessage(error, dispatch));
};

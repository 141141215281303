import Button from "../../../../components/CustomButtons/Button";
import Dialog from "@material-ui/core/Dialog";
import Card from "../../../../components/Card/Card";
import DialogTitle from "@material-ui/core/DialogTitle";
import CardHeader from "../../../../components/Card/CardHeader";
import Close from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import GridItem from "../../../../components/Grid/GridItem";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js";
import Slide from "@material-ui/core/Slide";
import GridContainer from "../../../../components/Grid/GridContainer";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { registerUser } from "../../../../store/authenticationSlice";
import { push } from "connected-react-router";
import Paths from "../../../../staticData/Paths";
import RegisterForm from "../../../molecules/RegisterForm/RegisterForm";

const useStyles = makeStyles(javascriptStyles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

export default function RegisterDialog() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleClose = () => {
    dispatch(push(Paths.root));
  };

  const handleRegister = async (data) => {
    const success = await dispatch(registerUser(data));

    if (success) {
      dispatch(push(Paths.root));
    }
  };

  return (
    <Dialog
      classes={{
        root: classes.modalRoot,
        paper: classes.modal + " " + classes.modalSignup,
      }}
      open={true}
      TransitionComponent={Transition}
      onClose={handleClose}
      aria-labelledby="signup-modal-slide-title"
      aria-describedby="signup-modal-slide-description"
    >
      <Card plain className={classes.modalSignupCard}>
        <DialogTitle
          id="signup-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <CardHeader
            plain
            color="primary"
            className={classNames(classes.textCenter, classes.cardLoginHeader)}
          >
            <Button
              simple
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              onClick={handleClose}
            >
              {" "}
              <Close className={classes.modalClose} />
            </Button>
            <h3
              className={classNames(classes.cardTitleWhite, classes.modalTitle)}
            >
              Register
            </h3>
          </CardHeader>
        </DialogTitle>
        <DialogContent
          id="signup-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer>
            <GridItem
              xs={10}
              sm={10}
              md={10}
              className={classNames(classes.mrAuto, classes.mlAuto)}
            >
              <RegisterForm
                onSubmit={handleRegister}
                submitButton={() => (
                  <Button round type={"submit"} color="primary">
                    Get started
                  </Button>
                )}
              />
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Card>
    </Dialog>
  );
}

import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Endpoints from "../staticData/Endpoints";
import { toastr } from "react-redux-toastr";
import draftToHtml from "draftjs-to-html";
import { convertToRaw } from "draft-js";
import { push } from "connected-react-router";
import Paths from "../staticData/Paths";
import {
  openCopyPostDialog,
  setContextItem,
  closeCreateArticleDialog,
} from "./dialogsSlice";
import { displayValidationMessage } from "../utils/validationDisplayHelper";
import { createSelector } from "reselect";
import { setFavoritePosts } from "./favouritesSlice";
import { setOtherUserPosts } from "./otherUserSlice";
import { setMyAccountPosts } from "./myAccountSlice";
import { setLibraryPosts } from "./librarySlice";

const generalPostsSlice = createSlice({
  name: "posts",
  initialState: {
    data: [],
    details: {},
  },
  reducers: {
    addPosts(state, action) {
      action.payload.forEach((post) => {
        state.data.push(post);
      });
    },
    removePost(state, action) {
      state.data = state.data.filter((post) => post.id !== action.payload);
    },
    updateChallengeStatus(state, action) {
      state.data = state.data.map((post) => {
        if (post.id === action.payload) {
          return { ...post, isActive: false };
        }
        return post;
      });
    },
    clearPosts(state) {
      state.data = [];
    },
    setPostDetails(state, action) {
      state.details = action.payload;
    },
    addComment(state, action) {
      state.details.comments.unshift(action.payload);
    },
    updateComment(state, { payload }) {
      const { id } = payload;

      const index = state.details.comments.findIndex((x) => x.id === id);

      if (index !== -1) {
        state.details.comments[index] = payload;
      }
    },
    removeComment(state, { payload: { id } }) {
      const index = state.details.comments.findIndex((x) => x.id === id);

      if (index !== -1) {
        state.details.comments.splice(index, 1);
      }
    },
    addReply(state, action) {
      const { parentId } = action.payload;

      const index = state.details.comments.findIndex((x) => x.id === parentId);

      state.details.comments[index].replies.unshift(action.payload);
    },
    updateReply(state, { payload }) {
      const { id, parentId } = payload;

      const parentIndex = state.details.comments.findIndex(
        (x) => x.id === parentId
      );
      const index = state.details.comments[parentIndex].replies.findIndex(
        (x) => x.id === id
      );

      state.details.comments[parentIndex].replies[index] = payload;
    },
    removeReply(state, action) {
      const { id, parentId } = action.payload;

      const parentIndex = state.details.comments.findIndex(
        (x) => x.id === parentId
      );
      const index = state.details.comments[parentIndex].replies.findIndex(
        (x) => x.id === id
      );

      state.details.comments[parentIndex].replies.splice(index, 1);
    },
  },
});

export const {
  addPosts,
  removePost,
  clearPosts,
  setPostDetails,
  addComment,
  updateComment,
  removeComment,
  addReply,
  updateReply,
  removeReply,
  updateChallengeStatus,
} = generalPostsSlice.actions;

export default generalPostsSlice.reducer;

export const generalPostsSelectors = {
  postDetails: createSelector(
    (state) => state.posts.details,
    (details) => details
  ),
  posts: createSelector(
    (state) => state.posts.data,
    (data) => data
  ),
};

export const uploadFile = (data) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;

  const fileSizeInMB = Math.ceil(data.size / 1024 / 1024);
  const maxFileSize = 30;

  if (fileSizeInMB > maxFileSize) {
    toastr.error("", "Maximum file size is 30MB.");
    return;
  }

  const formData = new FormData();
  formData.append("image", data);

  return axios({
    method: "POST",
    url: Endpoints.uploadFile,
    data: formData,
    headers: {
      "content-type": "multipart/form-data",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then((result) => {
      if (result.status === 200) {
        return result.data;
      }
    })
    .catch((error) => {
      displayValidationMessage(error, dispatch);

      return false;
    });
};

export const uploadFiles = (files) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;

  // const fileSizeInMB = Math.ceil(data.size / 1024 / 1024);
  // const maxFileSize = 30;
  //
  // if (fileSizeInMB > maxFileSize) {
  //   toastr.error("", "Maximum file size is 30MB.");
  //   return;
  // }

  const formData = new FormData();

  files.forEach((x) => {
    formData.append("files", x.file);
    formData.append("frontEndIds", x.frontendId);
  });

  return axios({
    method: "POST",
    url: Endpoints.uploadMultipleFiles,
    data: formData,
    headers: {
      "content-type": "multipart/form-data",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then((result) => {
      if (result.status === 200) {
        return result.data;
      }
    })
    .catch((error) => {
      displayValidationMessage(error, dispatch);

      return false;
    });
};

export const checkConversionStatus = (data) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;

  return axios({
    method: "POST",
    url: Endpoints.checkConversionStatus,
    data: JSON.stringify({ urls: data }),
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then((result) => {
      if (result.status === 200) {
        return result.data;
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getPostDetails = (id) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;
  const locationState = state.router.location.state;
  const guestCode = locationState?.guestCode;

  return axios({
    method: "GET",
    url: Endpoints.getPostDetails,
    params: { id, ...(guestCode && { code: guestCode }) },
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then((result) => {
      const { data, status } = result;

      if (status === 200) {
        return data;
      }
    })
    .catch((error) => displayValidationMessage(error, dispatch));
};

export const copyPost = (id) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;

  axios({
    method: "GET",
    url: Endpoints.copyPost,
    params: { id },
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then((result) => {
      if (result.status === 200) {
        dispatch(setContextItem(result.data));
        dispatch(openCopyPostDialog());
      }
    })
    .catch((error) => displayValidationMessage(error, dispatch));
};

export const endChallenge = (id) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;

  return axios({
    method: "GET",
    url: Endpoints.endChallenge,
    params: { id },
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(getPostDetails(id));
        dispatch(updateChallengeStatus(id));

        toastr.success("", "Challenge ended successfully.");
        return true;
      }
    })
    .catch((error) => displayValidationMessage(error, dispatch));
};

export const addPostToFavorites = (data) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;
  const { id: postId } = data;

  return axios({
    method: "POST",
    url: Endpoints.addPostToFavorites,
    data: JSON.stringify({ postId }),
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then((response) => {
      const { status } = response;

      if (status === 200) {
        toastr.success("", "Post successfully added to Favorites.");
        return true;
      }
    })
    .catch((error) => displayValidationMessage(error, dispatch));
};

export const submitComment = (data) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;

  const { editorState, postId, parentId, email, attachments = [] } = data;

  const editorContentHasValidLength =
    editorState.getCurrentContent().getPlainText().length > 0;

  if (!editorContentHasValidLength) {
    return toastr.error("", "Comment text is required.");
  }

  const formData = new FormData();
  const editorData = draftToHtml(convertToRaw(editorState.getCurrentContent()));

  formData.append("text", editorData);
  formData.append("entityId", postId);
  parentId && formData.append("parentId", parentId);
  email && formData.append("email", email);

  if (attachments.length > 0) {
    attachments.forEach(({ file }) => formData.append("files", file));
  }

  return axios({
    method: "POST",
    url: Endpoints.addPostComment,
    data: formData,
    headers: {
      "content-type": "application/json",
      ...(!!authTokens && { authorization: `Bearer ${authTokens}` }),
    },
  })
    .then((response) => {
      if (response.status === 200) {
        if (parentId) {
          dispatch(addReply(response.data.comment));
        } else {
          dispatch(addComment(response.data.comment));
        }
        return true;
      }
    })
    .catch((error) => displayValidationMessage(error, dispatch));
};

export const editComment = (data) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;

  const { editorState, id, attachments = [] } = data;

  const editorContentHasValidLength =
    editorState.getCurrentContent().getPlainText().length > 0;

  if (!editorContentHasValidLength) {
    return toastr.error("", "Comment text is required.");
  }

  const formData = new FormData();
  const editorData = draftToHtml(convertToRaw(editorState.getCurrentContent()));

  formData.append("text", editorData);
  formData.append("id", id);

  if (attachments.length > 0) {
    attachments.forEach(({ file, url }) => {
      if (file) {
        formData.append("files", file);
      }
      if (url) {
        formData.append("urls", url);
      }
    });
  }

  return axios({
    method: "PUT",
    url: Endpoints.editPostComment,
    data: formData,
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then((response) => {
      if (response.status === 200) {
        const { parentId } = response.data;

        if (parentId) {
          dispatch(updateReply(response.data));
        } else {
          dispatch(updateComment(response.data));
        }
        return true;
      }
    })
    .catch((error) => displayValidationMessage(error, dispatch));
};

export const deleteComment = (data) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;
  const { id, parentId } = data;

  axios({
    method: "DELETE",
    url: Endpoints.deletePostComment,
    params: { id },
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then((response) => {
      if (response.data) {
        if (parentId) {
          dispatch(removeReply({ id, parentId }));
        } else {
          dispatch(removeComment({ id }));
        }
      }
    })
    .catch((error) => displayValidationMessage(error, dispatch));
};

export const getTagSuggestions = () => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;

  if (!authTokens) return;

  return axios({
    method: "GET",
    url: Endpoints.getTagSuggestions,
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then((result) => {
      if (result.status === 200) {
        return result.data;
      }
    })
    .catch((error) => displayValidationMessage(error, dispatch));
};

export const getBoardSuggestions = (data) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;

  const { isPostPrivate, itemId = 0 } = data;

  return axios({
    method: "GET",
    url: `/api/Article/GetBoardSuggestions/${isPostPrivate || false}/${itemId}`,
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  });
};

export const getShareSuggestionsForPosts = (data) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;
  const { key, postId } = data;

  return axios({
    method: "GET",
    url: Endpoints.getShareSuggestions
      .replace(":shareEntity", "post")
      .replace(":entityId", postId)
      .replace(":searchText", key),
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then((result) => {
      if (result.status === 200) {
        return result.data;
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getSharedForPost = (data) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;
  const { postId } = data;

  return axios({
    method: "GET",
    url: Endpoints.getShared
      .replace(":shareEntity", "post")
      .replace(":entityId", postId),
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then((result) => {
      const { data, status } = result;

      if (status === 200) {
        return data;
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const activateGuestCodeForPost = (data) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;
  const { postId } = data;

  return axios({
    method: "GET",
    url: Endpoints.activateGuestCodeForPost,
    params: { id: postId },
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then((result) => {
      const { data, status } = result;

      if (status === 200) {
        return data;
      }
    })
    .catch((error) => displayValidationMessage(error, dispatch));
};

export const deactivateGuestCodeForPost = (data) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;
  const { postId } = data;

  return axios({
    method: "DELETE",
    url: Endpoints.deactivateGuestCodeForPost,
    params: { id: postId },
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then((result) => {
      const { data, status } = result;

      if (status === 200) {
        return data;
      }
    })
    .catch((error) => displayValidationMessage(error, dispatch));
};

export const sharePost = (data) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;
  const { entityId, usersAndGroups, message } = data;

  const { shareEntities, groupIds } = usersAndGroups.reduce(
    (acc, elem) => {
      elem.groupId
        ? acc.groupIds.push(elem.groupId)
        : acc.shareEntities.push({ email: elem.email, userId: elem.userId });

      return acc;
    },
    {
      shareEntities: [],
      groupIds: [],
    }
  );

  return axios({
    method: "POST",
    url: Endpoints.sharePost,
    data: JSON.stringify({ entityId, shareEntities, groupIds, message }),
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then((result) => {
      const { status } = result;

      if (status === 200) {
        toastr.success("", "Post was successfully shared.");
        return true;
      }
    })
    .catch((error) => displayValidationMessage(error, dispatch));
};

export const createPost = (data) => (dispatch, getState) => {
  const state = getState();
  const { formValues, attachments, editorState } = data;

  const { authTokens } = state.authentication;

  return axios({
    method: "POST",
    url: Endpoints.createPost,
    data: {
      private: formValues.isPrivate,
      type: formValues.type,
      attachments: attachments,
      body: draftToHtml(convertToRaw(editorState.getCurrentContent())),
      ...(formValues.boards && { boardId: formValues.boards }),
      ...(formValues.type === "event" && {
        eventData: {
          title: formValues.eventTitle,
          phoneNumber: formValues.phoneNumber,
          address: formValues.location.address,
          latitude: formValues.location.latitude,
          longitude: formValues.location.longitude,
          webinarLink: formValues.webinarLink,
          startDate: formValues.startTime?.toISOString(),
          endDate: formValues.endTime?.toISOString(),
        },
      }),
    },
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then((result) => {
      const { status } = result;

      if (status === 200) {
        return true;
      }
    })
    .catch((error) => displayValidationMessage(error, dispatch));
};

export const saveAndNotifyPost = (data) => (dispatch, getState) => {
  const state = getState();
  const { formValues, attachments, editorState } = data;

  const { authTokens } = state.authentication;

  return axios({
    method: "POST",
    url: Endpoints.saveAndNotifyPost,
    data: {
      private: formValues.isPrivate,
      type: formValues.type,
      attachments: attachments,
      body: draftToHtml(convertToRaw(editorState.getCurrentContent())),
      ...(formValues.boards && { boardId: formValues.boards }),
      ...(formValues.type === "event" && {
        eventData: {
          title: formValues.eventTitle,
          phoneNumber: formValues.phoneNumber,
          address: formValues.location.address,
          latitude: formValues.location.latitude,
          longitude: formValues.location.longitude,
          webinarLink: formValues.webinarLink,
          startDate: formValues.startTime?.toISOString(),
          endDate: formValues.endTime?.toISOString(),
        },
      }),
    },
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then((result) => {
      const { status, data } = result;

      if (status === 200) {
        dispatch(push(Paths.postDetails.replace(":id", data.id)));
        return true;
      }
    })
    .catch((error) => displayValidationMessage(error, dispatch));
};

export const cancelCreatePost = () => (dispatch, getState) => {
  const state = getState();

  const { authTokens } = state.authentication;

  axios({
    method: "DELETE",
    url: Endpoints.cancelCreatePost,
    headers: {
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then((result) => {
      if (result.status === 200) {
        return dispatch(closeCreateArticleDialog());
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const editPost = (data) => (dispatch, getState) => {
  const state = getState();
  const { formValues, attachments, editorState } = data;

  const contextItemId = state.dialogs.contextItem.id;
  const { authTokens } = state.authentication;

  return axios({
    method: "PUT",
    url: Endpoints.editPost,
    data: {
      id: contextItemId,
      private: formValues.isPrivate === "true",
      type: formValues.type,
      body: draftToHtml(convertToRaw(editorState.getCurrentContent())),
      attachments: attachments.map((x) => ({ url: x.url, type: x.type })),
      ...(formValues.type === "event" && {
        eventData: {
          title: formValues.eventTitle,
          phoneNumber: formValues.phoneNumber,
          address: formValues.location.address,
          latitude: formValues.location.latitude,
          longitude: formValues.location.longitude,
          webinarLink: formValues.webinarLink,
          startDate: formValues.startTime?.toISOString(),
          endDate: formValues.endTime?.toISOString(),
        },
      }),
    },
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then((result) => {
      const { status } = result;

      if (status === 200) {
        return true;
      }
    })
    .catch((error) => displayValidationMessage(error, dispatch));
};

export const editAndNotifyPost = (data) => (dispatch, getState) => {
  const state = getState();
  const { formValues, attachments, editorState } = data;

  const contextItemId = state.dialogs.contextItem.id;
  const { authTokens } = state.authentication;

  return axios({
    method: "PUT",
    url: Endpoints.editAndNotifyPost,
    data: {
      id: contextItemId,
      private: formValues.isPrivate === "true",
      type: formValues.type,
      body: draftToHtml(convertToRaw(editorState.getCurrentContent())),
      attachments: attachments.map((x) => ({ url: x.url, type: x.type })),
      ...(formValues.type === "event" && {
        eventData: {
          title: formValues.eventTitle,
          phoneNumber: formValues.phoneNumber,
          address: formValues.location.address,
          latitude: formValues.location.latitude,
          longitude: formValues.location.longitude,
          webinarLink: formValues.webinarLink,
          startDate: formValues.startTime?.toISOString(),
          endDate: formValues.endTime?.toISOString(),
        },
      }),
    },
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then((result) => {
      const { status } = result;

      if (status === 200) {
        return true;
      }
    })
    .catch((error) => displayValidationMessage(error, dispatch));
};

export const editCopy = (data) => (dispatch, getState) => {
  const state = getState();
  const { formValues, attachments, editorState } = data;

  const contextItemId = state.dialogs.contextItem.id;
  const { authTokens } = state.authentication;

  return axios({
    method: "PUT",
    url: Endpoints.editCopyPost,
    data: {
      id: contextItemId,
      private: formValues.isPrivate === "true",
      type: formValues.type,
      body: draftToHtml(convertToRaw(editorState.getCurrentContent())),
      attachments: attachments.map((x) => ({ url: x.url, type: x.type })),
      ...(formValues.type === "event" && {
        eventData: {
          title: formValues.eventTitle,
          phoneNumber: formValues.phoneNumber,
          address: formValues.location.address,
          latitude: formValues.location.latitude,
          longitude: formValues.location.longitude,
          webinarLink: formValues.webinarLink,
          startDate: formValues.startTime?.toISOString(),
          endDate: formValues.endTime?.toISOString(),
        },
      }),
    },
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then((result) => {
      const { status } = result;

      if (status === 200) {
        return true;
      }
    })
    .catch((error) => displayValidationMessage(error, dispatch));
};

export const deletePost = (data) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;
  const { id } = data;

  return axios({
    method: "DELETE",
    url: Endpoints.deletePost,
    params: { id },
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then((result) => {
      if (result.status === 200) {
        return true;
      }
    })
    .catch((error) => displayValidationMessage(error, dispatch));
};

// GET POSTS ACTIONS

export const getPostsByKeyword = (data) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;

  const { lastUpdatedOn, cancelToken, searchText } = data;

  return axios({
    method: "GET",
    cancelToken: cancelToken.token,
    url: Endpoints.searchPostsByKeyword,
    params: {
      ...(lastUpdatedOn && { lastUpdatedOn }),
      key: searchText,
    },
    headers: {
      "content-type": "application/json",
      ...(!!authTokens && { authorization: `Bearer ${authTokens}` }),
    },
  })
    .then(({ data }) => {
      const { posts, areMore } = data;

      dispatch(addPosts(posts));

      return {
        areMore,
        lastUpdatedOn: posts[posts.length - 1]?.lastUpdatedOn,
      };
    })
    .catch((error) => displayValidationMessage(error, dispatch));
};

export const getPostsByTags = (data) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;

  const { lastUpdatedOn, cancelToken, tagList } = data;

  return axios({
    method: "POST",
    cancelToken: cancelToken.token,
    url: Endpoints.searchPostsByTag,
    data: JSON.stringify({
      ...(lastUpdatedOn && { lastUpdatedOn }),
      tags: tagList,
    }),
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then(({ data }) => {
      const { areMore, posts } = data;

      dispatch(addPosts(posts));

      return {
        areMore,
        lastUpdatedOn: posts[posts.length - 1]?.lastUpdatedOn,
      };
    })
    .catch((error) => displayValidationMessage(error, dispatch));
};

export const getOtherUserPosts = (data) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;
  const { lastUpdatedOn, cancelToken, userIdFromUrl } = data;

  return axios({
    method: "GET",
    cancelToken: cancelToken.token,
    url: Endpoints.getOtherUserPostsList,
    params: {
      lastUpdatedOn,
      userId: userIdFromUrl,
    },
    headers: {
      "content-type": "multipart/form-data",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then(({ data }) => {
      const { areMore, posts } = data;

      dispatch(setOtherUserPosts(posts));

      return {
        areMore,
        lastUpdatedOn: posts[posts.length - 1]?.lastUpdatedOn,
      };
    })
    .catch((error) => displayValidationMessage(error, dispatch));
};

export const getFavoritesPosts = (data) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;
  const { lastUpdatedOn, cancelToken } = data;

  return axios({
    method: "GET",
    cancelToken: cancelToken.token,
    url: Endpoints.getFavoritePosts,
    params: {
      lastUpdatedOn,
    },
    headers: {
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then(({ data }) => {
      const { posts, areMore } = data;

      dispatch(setFavoritePosts(posts));

      return {
        areMore,
        lastUpdatedOn: posts[posts.length - 1]?.lastUpdatedOn,
      };
    })
    .catch((error) => displayValidationMessage(error, dispatch));
};

export const getMyAccountPosts = (data) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;
  const { lastUpdatedOn, cancelToken } = data;

  return axios({
    method: "GET",
    cancelToken: cancelToken.token,
    url: Endpoints.getArticleList,
    params: {
      location: "myAccount",
      lastUpdatedOn,
    },
    headers: {
      "content-type": "multipart/form-data",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then(({ data }) => {
      const { areMore, posts } = data;

      dispatch(setMyAccountPosts(posts));

      return {
        areMore,
        lastUpdatedOn: posts[posts.length - 1]?.lastUpdatedOn,
      };
    })
    .catch((error) => displayValidationMessage(error, dispatch));
};

export const getLibraryPosts = (data) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;
  const { lastUpdatedOn, cancelToken } = data;

  return axios({
    method: "GET",
    cancelToken: cancelToken.token,
    url: Endpoints.getArticleList,
    params: {
      location: "library",
      lastUpdatedOn,
    },
    headers: {
      "content-type": "multipart/form-data",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then(({ data }) => {
      const { posts, areMore } = data;

      dispatch(setLibraryPosts(posts));

      return {
        areMore,
        lastUpdatedOn: posts[posts.length - 1]?.lastUpdatedOn,
      };
    })
    .catch((error) => displayValidationMessage(error, dispatch));
};

export const getBoardDetailsPosts = (data) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;
  const { lastUpdatedOn, cancelToken, boardId } = data;

  return axios({
    method: "GET",
    cancelToken: cancelToken.token,
    url: Endpoints.getBoardPostsList,
    params: {
      id: boardId,
      lastUpdatedOn,
    },
    headers: {
      "content-type": "multipart/form-data",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then(({ data }) => {
      const { areMore, posts } = data;

      dispatch(addPosts(posts));

      return {
        areMore,
        lastUpdatedOn: posts[posts.length - 1]?.lastUpdatedOn,
      };
    })
    .catch((error) => displayValidationMessage(error, dispatch));
};

export const getPublicPosts = (data) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;
  const { lastUpdatedOn, cancelToken } = data;

  return axios({
    method: "GET",
    cancelToken: cancelToken.token,
    url: Endpoints.getPublicPostsList,
    params: {
      lastUpdatedOn,
    },
    headers: {
      "content-type": "multipart/form-data",
      ...(authTokens && { authorization: `Bearer ${authTokens}` }),
    },
  })
    .then(({ data }) => {
      const { posts, areMore } = data;

      dispatch(addPosts(posts));

      return {
        areMore,
        lastUpdatedOn: posts[posts.length - 1]?.lastUpdatedOn,
      };
    })
    .catch((error) => displayValidationMessage(error, dispatch));
};

import React, { useCallback, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import libraryPageStyle from "../../../ArticlesView/LibraryPageStyle";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useDispatch, useSelector } from "react-redux";
import { clearPosts, getPublicPosts, generalPostsSelectors } from "store/generalPostsSlice";
import SizedStackGrid from "components/SizedStackGrid/SizedStackGrid";
import {
  generalPostActionTypes,
  usePostCard,
} from "utils/hooks/postCardOptions";
import { authenticationSelectors } from "store/authenticationSlice";
import { useInfiniteLoading } from "../../../../utils/hooks/infiniteLoading";
import { routerSelectors } from "../../../../store/routerActions";

const useStyles = makeStyles(libraryPageStyle);

export default function PublicPostsView() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const locationKey = useSelector(routerSelectors.getLocationKey);
  const authTokens = useSelector(authenticationSelectors.authTokens);
  const posts = useSelector(generalPostsSelectors.posts);

  const apiCall = useCallback((data) => getPublicPosts(data), []);

  const {
    bottomBoundaryNode,
    resetPagination,
    cancelOngoingCall,
  } = useInfiniteLoading({ apiCall });

  useEffect(() => {
    dispatch(clearPosts());
    resetPagination();

    return () => cancelOngoingCall();
  }, [dispatch, resetPagination, authTokens, locationKey, cancelOngoingCall]);

  const { postCardRenderer } = usePostCard(generalPostActionTypes);

  return (
    <div className={classes.container} style={{ paddingTop: "30px" }}>
      <SizedStackGrid>{posts.map(postCardRenderer)}</SizedStackGrid>
      {bottomBoundaryNode}
    </div>
  );
}

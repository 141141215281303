const Endpoints = {
  login: "/api/Authentication/Login",
  register: "/api/Authentication/Register",
  requestResetPassword: "/api/Authentication/RequestResetPassword",
  resetPassword: "/api/Authentication/ResetPassword",
  getMyAccount: "/api/User/GetSelf",
  updateProfile: "/api/User/UpdateProfile",
  updateProfilePicture: "/api/User/UpdateProfilePicture",
  createBoard: "/api/Boards/CreateBoard",
  createBoardAndNotify: "/api/Boards/SaveAndNotify",

  //get posts
  getArticleList: "/api/Article/GetList",
  getOtherUserPostsList: "/api/Article/OtherUserPosts",
  getPublicPostsList: "/api/Article/GetPublic",
  getBoardPostsList: "/api/Boards/GetBatch",
  searchPostsByKeyword: "/api/Article/Search",
  searchPostsByTag: "/api/Article/GetBasedOnTag",
  getFavoritePosts: "/api/Article/GetFavorites",

  //get boards
  getBoards: "/api/Boards/GetList",
  getUsersAccountBoards: "/api/Boards/OtherUserBoards",
  getPublicBoards: "/api/Boards/GetPublic",
  searchBoardsByKeyword: "/api/Boards/Search",
  searchBoardsByTag: "/api/Boards/GetBasedOnTag",
  getFollowingBoards: "api/Boards/GetFollowed",

  removePostFromBoard: "/api/Boards/RemoveFromBoard",
  getBoardDetails: "/api/Boards/GetSingleBoard",
  editBoard: "/api/Boards/UpdateBoard",
  deleteBoard: "/api/Boards/Delete",
  shareBoard: "/api/Share/ShareBoard",
  sharePost: "/api/Share/Share",
  getShared: "/api/Share/GetShared/:shareEntity/:entityId",
  activateGuestCodeForPost: "/api/Article/PublicToken",
  deactivateGuestCodeForPost: "/api/Article/DeactivateToken",
  activateGuestCodeForBoard: "/api/Boards/PublicToken",
  deactivateGuestCodeForBoard: "/api/Boards/DeactivateToken",
  getByToken: "/api/Token/Search",
  getShareSuggestions:
    "/api/Share/ShareSuggestions/:shareEntity/:entityId/:searchText",
  removeUserShareAccess:
    "/api/Share/RemoveAccess/:entityType/:userEmail/:entityId",
  removeGroupShareAccess:
    "/api/Share/RemoveGroupAccess/:entityType/:groupId/:entityId",
  addToBoard: "/api/Boards/AddToBoard",
  followBoard: "/api/User/FollowBoard",
  unfollowBoard: "/api/User/UnfollowBoard",
  uploadFile: "/api/Article/UploadArticleImage",
  uploadMultipleFiles: "/api/Article/UploadMultiple",
  checkConversionStatus: "/api/CloudConvert/CheckConversionStatus",
  createPost: "/api/Article/CreateArticle",
  saveAndNotifyPost: "/api/Article/SaveAndNotify",
  editPost: "/api/Article/Update",
  editAndNotifyPost: "/api/Article/UpdateAndNotify",
  deletePost: "/api/Article/Delete",
  cancelCreatePost: "/api/Article/CancelCreate",
  getTagSuggestions: "/api/Tag/TagSuggestions",
  addPostComment: "/api/Comment/Add",
  editPostComment: "/api/Comment/Update",
  deletePostComment: "/api/Comment/Delete",
  getPostDetails: "/api/Article/GetSingleArticle",
  endChallenge: "/api/Article/EndChallenge",
  copyPost: "/api/Article/Copy",
  facebookAuth: "/api/Authentication/FacebookAuth",
  linkedInAuth: "/api/Authentication/signin-linkedin",
  editCopyPost: "/api/Article/UpdateCopy",
  libraryCalendar: "/api/Article/EventsLibrary",
  exploreCalendar: "/api/Article/PublicEvents",

  getConnectionsPeople: "/api/Connections/:userId",
  getGroupList: "/api/Groups/List/:groupId",
  getGroupDetails: "/api/Groups/:groupId",
  getGroupMembers: "/api/Groups/Members/:groupId/:memberId",
  createGroup: "/api/Groups/Create",
  editGroup: "/api/Groups/Update",
  deleteGroup: "/api/Groups/Delete/:groupId",
  addUsersToGroup: "/api/Groups/Add",
  removeUserFromGroup: "/api/Groups/Remove",
  leaveGroup: "/api/Groups/Leave/:groupId",
  getPeopleSuggestionsForGroup: "/api/Groups/Suggestions/:groupId/:searchText",

  addPostToFavorites: "/api/Article/AddToFavorites",
  removePostFromFavorites: "/api/Article/RemoveFromFavorites",

  //Following
  getFollowingUsers: "/api/User/GetFollowing",
  getFollowingTags: "/api/Tag/GetFollowed",
  getTagFollowingStatus: "/api/Tag/IsFollowingTag",
  getUserProfileData: "/api/User/GetUserProfile",
  followUser: "/api/User/Follow",
  unfollowUser: "/api/User/Unfollow",
  followTag: "/api/User/FollowTag",
  unfollowTag: "/api/User/UnfollowTag",

  //Admin
  addToPremium: "/api/Admin/AddToPremium",
  removeFromPremium: "/api/Admin/RemoveFromPremium",
  addToAdmin: "/api/Admin/AddToAdmin",
  removeFromAdmin: "/api/Admin/RemoveFromAdmin",
  removeContent: "/api/Admin/RemoveContent",
};

export default Endpoints;
